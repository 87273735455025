@import 'tailwindcss';

@theme {
    --color-primary: #1f32d6;
    --font-pp: 'Poppins', sans-serif;
    --font-ppM: 'Poppins-Medium', sans-serif;
    --font-poppinsSB: 'Poppins-SemiBold', sans-serif;
    --font-oxanium: 'Oxanium', cursive;
    --font-oxaniumM: 'Oxanium-Medium', cursive;
    --font-oxaniumSB: 'Oxanium-SemiBold', cursive;
}
